<template>
  <div>
    <div v-if="hasData">
      <b-row>
        <b-col xl="9" class="overflow-hidden">
          <scoring-gauge
            :data="data"
          />
        </b-col>
        <b-col xl="3" class="mt-3 mt-lg-0 d-lg-flex flex-lg-column justify-content-lg-center py-lg-5">
          <b-form-row
            class="h-100 d-flex flex-lg-column justify-content-lg-evenly align-items-stretch py-lg-3"
          >
            <b-col cols="6" lg="12" class="flex-grow-1">
              <b-card class="card--cta h-100" body-class="d-flex flex-column justify-content-center">
                <div class="card--cta__title">{{creditLimitLabel}}</div>
                <div class="card--cta__subtitle">{{creditLimitData}}</div>
              </b-card>
            </b-col>
            <b-col cols="6" lg="12" class="mt-lg-3">
              <b-card
                class="card--cta"
              >
                <template #header>
                  {{feedbackDataTitle}}
                </template>
                <div class="pie-holder">
                  <div class="pie-value pie-value--left">
                    {{pieValueOk}}
                    <span>POS</span>
                  </div>
                  <div class="pie-value pie-value--right">
                    {{pieValueKo}}
                    <span>NEG</span>
                  </div>
                  <div :class="pieContainerClasses">
                    <div class="pieBackground"></div>
                    <div id="pieSlice1" class="hold">
                      <div class="pie" :style="pieSliceFirstSize" />
                    </div>
                    <div id="pieSlice2" class="hold" v-if="pieSecondSliceCheck">
                      <div class="pie" :style="pieSliceSecondSize" />
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-form-row>
        </b-col>
      </b-row>
    </div>
    <div v-else class="py-3">
      <p><em>Nessun dato di dashboard disponibile.</em></p>
    </div>
  </div>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';
import { formatCurrencyHuman } from '@/utils/formatterHelper';

const ScoringGauge = () => import('@/components/scoringGauge/ScoringGauge.vue');

export default {
  name: 'ProfileTabsDashboardMerits',
  components: { ScoringGauge },
  props: {
    data: Object,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      chartPlaceholder: require('@/assets/img/Report-CRP-chart_placeholder.png'),
    };
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    hasData() {
      return isNotEmpty(this.data);
    },
    creditLimitLabel() {
      return 'LIMITE DI CREDITO';
    },
    creditLimitData() {
      // console.log('this.data credit', this.data);
      if (isNotEmpty(this.data?.scoring?.creditLimit)) {
        const val = formatCurrencyHuman(this.data.scoring.creditLimit, 'en', 'EUR');
        // console.log('inte', this.data.scoring.creditLimit, int);
        // const val = formatCurrency(this.data.scoring.creditLimit, 'it', 'EUR');
        return `${val}`;
      }
      return this.notAvailableMsg;
    },
    feedbackDataTitle() {
      return 'SEGNALAZIONI CRP';
    },
    pieValueOk() {
      const a = this.data?.score?.reportReceived?.globalVotes?.countPositive;
      const b = this.data?.score?.reportReceived?.globalVotes?.countTotal;
      const baseCheck = a / b;
      if (a > 0 && b > 0) return `${baseCheck * 100}%`;
      return this.notAvailableMsg;
    },
    pieValueKo() {
      const a = this.data?.score?.reportReceived?.globalVotes?.countNegative;
      const b = this.data?.score?.reportReceived?.globalVotes?.countTotal;
      const baseCheck = a / b;
      if (a > 0 && b > 0) return `${baseCheck * 100}%`;
      return this.notAvailableMsg;
    },
    pieValueKoBase360() {
      const a = this.data?.score?.reportReceived?.globalVotes?.countNegative;
      const b = this.data?.score?.reportReceived?.globalVotes?.countTotal;
      return (a / b) * 360;
    },
    pieSliceFirstSize() {
      if (this.pieValueKoBase360 > 180) return 'transform:rotate(180deg);';
      return `transform:rotate(${this.pieValueKoBase360}deg);`;
    },
    pieSecondSliceCheck() {
      return this.pieValueKoBase360 > 180;
    },
    pieSliceSecondSize() {
      const computed = this.pieValueKoBase360 - 180;
      return `transform:rotate(${computed}deg);`;
    },
    pieContainerClasses() {
      const baseClasses = 'pieContainer';
      if (this.data?.score?.reportReceived?.globalVotes?.countTotal === 0) return `${baseClasses} has-no-votes`;
      return baseClasses;
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
